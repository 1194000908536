<template>
  <TableWidgetStyled>
    <TableV2
      :title="$tc('widgetTypes.ERROR_LOG')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :enableSearch="isWidget"
      :isMobile="isMobile"
      :enableFooter="isWidget"
      :messages="messages"
      :dropDownSelectorsAvailable="!isWidget"
      :dropDownSelectors="hourFilters"
      :customOptions="'errorTable.'"
      :customLabels="'errorTable.'"
      :preselectionId="'24h'"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
      @tableExport="tableExport"
      @textFilter="textSearch"
      @dropdownSelectorChange="updateTime"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'
import { get, partition, concat, orderBy, uniqBy, groupBy, compact } from 'lodash'
import { dateTimeShort, secondsTohhmmss } from '@/utils/filters/time'
import { formatDateAccordingToSettings } from '@/utils/time'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import { exportTableDataToCSV } from '@/utils/export'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import TableIndicatorCell from '../Atomic/Atoms/TableCells/TableStatusIndicatorCell.vue'

import { useAssetStore } from '@/stores/assets'

import ASSET_NOTIFICATIONS_QUERY from '#/graphql/assetNotifications/assetNotifications.gql'
import ASSET_ACTIVE_NOTIFICATIONS_QUERY from '#/graphql/operations/asset/machineHealthByMachine.gql'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`
export default {
  inject: ['uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, resizeMixin],
  props: {
    isWidget: {
      type: Boolean,
      default: true,
      require: false,
    },
    assetID: {
      type: String,
      default: null,
      require: false,
    },
    assetLastUpdate: {
      type: String,
      default: null,
      require: false,
    },
    widget: {
      type: Object,
      required: false,
    },
  },
  components: {
    TableWidgetStyled,
    TableV2,
  },
  filters: {
    dateTimeShort,
    secondsTohhmmss,
  },
  data() {
    return {
      assetNonActiveNotifications: [],
      assetActiveNotifications: [],
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'start',
        asc: true,
      },
      filterOptionsSelected: [],
      getActiveNotifications: true,
      profileTimeInterval: null,
    }
  },
  computed: {
    isLoading() {
      return this.$apollo.loading
    },
    isSingleAssetView() {
      return this.assetStore.isSingleAssetView
    },
    selectedAssets() {
      const assetsFromStore = this.assetStore.assetsSelected()
      return assetsFromStore
        .filter(asset => asset !== undefined)
        .map(asset => {
          return asset.id
        })
    },
    headers() {
      const headers = [
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type',
          size: 'small',
        },
        {
          id: `header_start`,
          label: this.$tc('start', 1),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'start',
          size: 'medium',
        },
        {
          id: `header_end`,
          label: this.$tc('end', 1),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'end',
          size: 'medium',
        },
        {
          id: `header_duration`,
          label: this.$tc('duration', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'duration',
          size: 'small',
        },
        {
          id: `header_severity`,
          label: this.$tc('severity', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'severity',
          size: 'xsmall',
        },
        {
          id: `header_code`,
          label: this.$tc('code', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'code',
          size: 'small',
        },
        {
          id: `header_label`,
          label: this.$tc('label', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'label',
          size: 'medium',
        },
        {
          id: `header_component`,
          label: this.$tc('category', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'component',
          size: 'medium',
        },
        {
          id: `header_description`,
          label: this.$tc('description', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'description',
          size: 'large',
        },
        {
          id: `header_action`,
          label: this.$tc('errorTable.recommendedAction', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'action',
          size: 'large',
        },
      ]
      return headers
    },
    uniqueErrorTypes() {
      return uniqBy(this.assetNotifications, 'type').map(assetNotification => {
        return { name: assetNotification.type, __typename: 'Type' }
      })
    },
    uniqueSeverity() {
      return uniqBy(this.assetNotifications, 'severity').map(assetNotification => {
        return { name: assetNotification.severity, __typename: 'Severity' }
      })
    },
    uniqueCodes() {
      const codes = uniqBy(this.assetNotifications, 'code').map(assetNotification => {
        return { name: assetNotification.code, __typename: 'Code' }
      })
      return orderBy(codes, 'name')
    },
    uniqueCategories() {
      return uniqBy(this.assetNotifications, 'component').map(assetNotification => {
        return { name: assetNotification.component, __typename: 'Component' }
      })
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_error_type',
          class: 'filter filter-error-type',
          modelName: 'type',
          placeholder: this.$tc('type', 2),
          searchable: false,
          options: this.uniqueErrorTypes.map(m => ({
            ...m,
            id: m.name,
            label: m.name,
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Type'),
          isMultiple: true,
        },
        {
          id: 'filter_severity',
          class: 'filter filter-severity',
          modelName: 'severity',
          placeholder: this.$tc('severity', 2),
          searchable: false,
          options: this.uniqueSeverity.map(m => ({
            ...m,
            id: m.name,
            label: this.$t(`errorTable.severity.${m.name.toLowerCase()}`),
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Severity'),
          isMultiple: false,
        },
        {
          id: 'filter_code',
          class: 'filter filter-code',
          modelName: 'code',
          placeholder: this.$tc('code', 2),
          searchable: false,
          options: this.uniqueCodes
            .filter(f => f.name !== '0')
            .map(m => {
              return {
                ...m,
                id: m.name,
                label: m.name,
              }
            }),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Code'),
          isMultiple: true,
        },
        {
          id: 'filter_component',
          class: 'filter filter-component',
          modelName: 'component',
          placeholder: this.$tc('category', 2),
          searchable: false,
          options: this.uniqueCategories.map(m => ({
            ...m,
            id: m.name,
            label: m.name,
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Component'),
          isMultiple: true,
        },
      ]
      return filters
    },
    assetNotificationsFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.assetNotifications.reduce((assetNotificationsFiltered, notification) => {
          const filterGroups = groupBy(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let assetPath
            let filterPath
            if (key === 'Type') {
              assetPath = 'type'
              filterPath = 'id'
            } else if (key === 'Severity') {
              assetPath = 'severity'
              filterPath = 'id'
            } else if (key === 'Code') {
              assetPath = 'code'
              filterPath = 'id'
            } else if (key === 'Component') {
              assetPath = 'component'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }
            const found = filterGroup.find(filter => get(filter, filterPath) === get(notification, assetPath))
            return !found
          })
          if (!notInAllGroups) {
            assetNotificationsFiltered.push(notification)
          }
          return assetNotificationsFiltered
        }, [])
      }
      return this.assetNotifications
    },
    assetNotificationsSorted() {
      const partitions = partition(this.assetNotificationsFiltered, x => !!get(x, this.sorting.key, null))
      const sortDirection =
        this.sorting.key === 'severity' || this.sorting.key === 'start' || this.sorting.key === 'end'
          ? this.sorting.asc
            ? 'desc'
            : 'asc'
          : this.sorting.asc
          ? 'asc'
          : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    rows() {
      const rows = this.assetNotificationsSorted.map((notification, key) => {
        const start = formatDateAccordingToSettings(DateTime.fromISO(notification.start), this.uiSettings, this.selectedTimezone)
        const end = notification.end
          ? formatDateAccordingToSettings(DateTime.fromISO(notification.end), this.uiSettings, this.selectedTimezone)
          : ''
        const duration = secondsTohhmmss(notification.duration)
        const code = notification?.code
        const label = notification.label
        const component = notification.component
        const description = notification.description === 'NaN' || !notification.description ? '-' : notification.description
        const type = notification.type === null ? '-' : notification.type
        const cells = [
          {
            id: `${key}_type`,
            sortableValue: type,
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: type,
            headerId: 'header_type',
          },
          {
            id: `${key}_start`,
            sortableValue: start,
            headerId: `header_start`,
          },
          {
            id: `${key}_end`,
            sortableValue: end,
            headerId: `header_end`,
          },
          {
            id: `${key}_duration`,
            sortableValue: duration,
            headerId: `header_duration`,
          },
          {
            id: `${key}_severity`,
            component: TableIndicatorCell,
            color: notification?.severity,
            isActive: notification?.end === null,
            sortableValue: notification?.severity,
            headerId: `header_severity`,
          },
          {
            id: `${key}_code`,
            sortableValue: code,
            headerId: `header_code`,
          },
          {
            id: `${key}_label`,
            sortableValue: label,
            headerId: `header_label`,
          },
          {
            id: `${key}_component`,
            sortableValue: component,
            headerId: `header_component`,
          },
          {
            id: `${key}_description`,
            sortableValue: description,
            headerId: `header_description`,
          },
          {
            id: `${key}_action`,
            sortableValue: notification?.action ? notification?.action : '--',
            headerId: `header_action`,
          },
        ]
        return {
          rowId: `row_${key}`,
          cells,
        }
      })
      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        if (!this.isSingleAssetView && this.isWidget) {
          message = this.$tc('messages.incompatibleDashboardType')
        } else {
          const dimensions = this.$tc('error', 1) + ', ' + this.$tc('warning', 1)
          message =
            this.searchQuery === ''
              ? this.$t('messages.noDimensionData', { dimension: dimensions })
              : this.$t('messages.searchNoResults', { query: this.searchQuery })
        }
      }
      return message
    },
    hourFilters() {
      return [
        {
          id: 'active',
          label: 'active',
        },
        {
          id: '1h',
          label: 'lastHour',
        },
        {
          id: '24h',
          label: 'lastDay',
        },
        {
          id: '7d',
          label: 'lastWeek',
        },
      ]
    },
    assetFromProfileScreen() {
      return [this.assetID]
    },
    assetNotifications() {
      return (!this.getActiveNotifications && !this.isWidget) || this.isWidget
        ? this.assetNonActiveNotifications
        : this.assetActiveNotifications
    },
    profileTimeframeGranularity() {
      if (!this.profileTimeInterval) {
        return null
      }
      return this.profileTimeInterval.length('days') > 2 ? 'P1D' : 'PT1H'
    },
    profileTimeframeParam() {
      if (!this.profileTimeInterval) {
        return null
      }
      const interval = this.profileTimeInterval
      const granularity = this.profileTimeframeGranularity
      const start = interval.start.toUTC().toISO()
      const end = interval.end.toUTC().toISO()
      return {
        start,
        end,
        timezone: DateTime.local().zoneName,
        granularity,
        shifts: [],
      }
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
    selectedErrorTypeName() {
      return this.widget?.widgetSettings?.errorTypeName.length > 0 ? this.widget?.widgetSettings?.errorTypeName : ['J1939_NEW', 'OEM']
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    tableExport(type) {
      if (type === 'csv') {
        const mappedHeaders = this.headers.map(header => ({ data: header.label }))
        const mappedRows = this.rows.flatMap(header => ({ data: header.cells })).flatMap(item => item.data)
        exportTableDataToCSV(mappedHeaders, mappedRows, 'talpa-export_error_log')
      }
    },
    textSearch(query) {
      this.searchQuery = query
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        if (filter.__typename === 'Severity') {
          this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.__typename !== 'Severity')
        }
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
    updateTime(args) {
      if (args.id === '24h') {
        this.profileTimeInterval = Interval.fromDateTimes(
          DateTime.fromISO(this.assetLastUpdate).minus({ hours: 24 }),
          DateTime.fromISO(this.assetLastUpdate),
        )
        this.getActiveNotifications = false
      }
      if (args.id === '1h') {
        this.profileTimeInterval = Interval.fromDateTimes(
          DateTime.fromISO(this.assetLastUpdate).minus({ hours: 1 }),
          DateTime.fromISO(this.assetLastUpdate),
        )
        this.getActiveNotifications = false
      }
      if (args.id === '7d') {
        this.profileTimeInterval = Interval.fromDateTimes(DateTime.local().minus({ day: 6 }).startOf('day'), DateTime.local().endOf('day'))
        this.getActiveNotifications = false
      }
      if (args.id === 'active') {
        this.getActiveNotifications = true
      }
    },
  },
  apollo: {
    assetNonActiveNotifications: {
      query: ASSET_NOTIFICATIONS_QUERY,
      variables() {
        return {
          where: {
            timeframe: this.isWidget ? this.selectedTimeframeParam : this.profileTimeframeParam,
            assetIds: this.isWidget ? this.selectedAssets : this.assetFromProfileScreen,
            filterBy: this.selectedErrorTypeName,
            locale: this.locale,
          },
        }
      },
      skip() {
        return (
          (this.isWidget && !this.selectedTimeframeParam.start) ||
          (this.isWidget && !this.selectedTimeframeParam.end) ||
          (this.isWidget && !this.isSingleAssetView) ||
          (this.isWidget && !this.selectedAssets.length) ||
          (!this.isWidget && this.getActiveNotifications) ||
          (!this.isWidget && !this.profileTimeframeParam) ||
          (!this.isWidget && !this.assetFromProfileScreen.length)
        )
      },
      update(data) {
        return data.assetNotifications
      },
    },
    assetActiveNotifications: {
      query: ASSET_ACTIVE_NOTIFICATIONS_QUERY,
      variables() {
        return {
          assetId: this.assetID,
        }
      },
      skip() {
        return (!this.getActiveNotifications && !this.isWidget) || this.isWidget
      },
      update({ machineHealthByMachine }) {
        return machineHealthByMachine[0]?.activeNotifications ?? []
      },
    },
  },
}
</script>
