<template>
  <TableCellStyled>
    <BadgeStyled :color="cell.color" :class="{ active: cell.isActive }"></BadgeStyled>
  </TableCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TableCellStyled = styled('div')`
  padding: 0.5rem;
  display: flex;
  @media (min-width: 1024px) {
    padding: 3rem;
  }
`
const BadgeStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 20px;
  height: 20px !important;
  border-radius: 50%;
  margin: auto;
  background: ${p => p.color};
  &.active {
    box-shadow: 0 0 18px 4px ${p => p.color};
  }
`
export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableCellStyled,
    BadgeStyled,
  },
}
</script>
