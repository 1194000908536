var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableWidgetStyled', [_c('TableV2', {
    attrs: {
      "title": _vm.$tc('widgetTypes.ERROR_LOG'),
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.isLoading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": _vm.isWidget,
      "isMobile": _vm.isMobile,
      "enableFooter": _vm.isWidget,
      "messages": _vm.messages,
      "dropDownSelectorsAvailable": !_vm.isWidget,
      "dropDownSelectors": _vm.hourFilters,
      "customOptions": 'errorTable.',
      "customLabels": 'errorTable.',
      "preselectionId": '24h'
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      },
      "setSelectedFilterOption": _vm.setSelectedFilterOption,
      "resetFilters": _vm.resetFilters,
      "tableExport": _vm.tableExport,
      "textFilter": _vm.textSearch,
      "dropdownSelectorChange": _vm.updateTime
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }